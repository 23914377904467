.visa-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
}

.visa-form-box {
    display: flex;
    flex-direction: column;
}

.visa-form-box label {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 8px;
}

.visa-form-box input[type="text"],
.visa-form-box input[type="file"] {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.visa-form-box input[type="text"]:focus,
.visa-form-box input[type="file"]:focus {
    border-color: #ef5da2;
}

.visa-form-box img {
    margin-top: 10px;
    max-width: 100px;
    height: auto;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.basic-multi-select {
    border-radius: 5px;
}

.select__control {
    border: 1px solid #ccc;
    box-shadow: none;
    transition: border-color 0.3s ease;
}

.select__control--is-focused {
    border-color: #ef5da2;
}

.select__option--is-selected {
    background-color: #ef5da2;
    color: white;
}

button[type="submit"] {
    padding: 12px 20px;
    background-color: #ef5da2;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}



@media (max-width: 768px) {
    .visa-form {
        max-width: 100%;
    }
}