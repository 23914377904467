/* Full-width Calendar */
.scheduler-container {
  width: 100%;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.calendar-container .react-calendar {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  background-color: #fff; /* Set the background color */
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.react-calendar__tile {
  color: #333; /* Change text color for all tiles */
}

.react-calendar__tile--active {
  background-color: #007bff;
  color: white;
}

.react-calendar__tile--now {
  background-color: #ff9800;
  color: white;
}

/* Change color of the month and year text */
.react-calendar__month-view__days__day--weekend,
.react-calendar__month-view__days__day {
  color: #333; /* Change text color for the days */
}

.react-calendar__navigation button {
  background-color: #fff;
  color: #007bff;
}

.react-calendar__navigation button:hover {
  background-color: #007bff;
  color: white;
}

.react-calendar__month-view__weekdays__weekday {
  color: #333;
}

.react-calendar__tile:disabled {
  color: #ccc; /* Light grey for disabled tiles */
}

/* Add styles for the selected tile to make it visible */
.react-calendar__tile--active {
  background-color: #007bff;
  color: white;
}

.notification-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.notification-input-container input {
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 400px;
}

.notification-input-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
}

.notification-input-container button:hover {
  background-color: #0056b3;
}

.manual-notification-button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}

.manual-notification-button:hover {
  background-color: #218838;
}

.notification-list {
  margin-top: 40px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.notification-list table {
  width: 100%;
  border-collapse: collapse;
}

.notification-list th,
.notification-list td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.notification-list th {
  background-color: #f1f1f1;
  font-weight: bold;
}

.notification-list tr:hover {
  background-color: #f1f1f1;
}
