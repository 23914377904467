.navbar{display: none; position:relative;}


.navbar .fa-chevron-right{margin-right:0; margin-top:-11px; background:linear-gradient(262deg, #00594F 0%, #004039 100%); padding:16px 14px 16px 0px !important;color:#ffffff !important; position: absolute;right: 0px; top:12px;}
.navbar .fa-chevron-right:hover{background:linear-gradient(262deg, #00594F 0%, #004039 100%); color:#fff !important;}

.navbar .fa{
  display: inline;
  padding: 5px 0;color:#202833;
}


ul.pushNav {
  padding: 0;
  margin: 0;
  list-style-type: none;
  overflow-y:auto;
  overflow-x: hidden;
  height:100vh;
}
ul.pushNav li{position:relative;}
ul.pushNav li img{width:auto; height:20px; padding-right:7px;}

.pushNav {
  height: 100%;
  position: fixed;
  top: 0;
  z-index:999;
  overflow: hidden;
  background:#ffffff;
  transition: ease-in-out 0.5s;
}
.pushNav hr {
  border: 1px solid #555;
}

.pushNav, .pushNav a {
  font-size: 1em;
  color: #444; 
  text-decoration: none;
}

.pushNavIsOpen {
  overflow: auto;
  height: 100%;
}

.js-topPushNav.isOpen, .pushNav_level.isOpen {
  right: 0;
}

.closeLevel, .openLevel {
  cursor: pointer;
}

.openLevel, .closeLevel, .pushNav a {
  padding: 0.7rem 0rem;
  display: block;
  text-indent: 20px;
  transition: background 0.4s ease-in-out;
  border-bottom: 1px dashed #ADADAD;position: relative;
}
.openLevel:hover, .closeLevel:hover, .pushNav a:hover {
  background:#ffecdc;color:#ff7800;
}

.hdg {
  background-color:#fff;
}

.closeLevel, closelevel > i {
  font-size: 1em;
  color:#202833;
}

.burger {
  position: relative;
  cursor: pointer;margin-top:3px;
}

.burger i {
  font-size:25px;color:#ff7800;
}

.screen {
  z-index:666;
  position: fixed;
  background:rgba(37, 42, 50, 0.6);
  width: 100%;
  height: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.pushNavIsOpen .screen {
  height: 100%;
  opacity: 1;
}


@media screen and (max-width: 600px) {
  .pushNav {
    width:100%;
    right: -100%;
  }
}
@media screen and (min-width: 601px) {
  .pushNav {
    width: 350px;
    right: -350px;
  }
}

@media screen and (max-width: 880px) {.navbar{display:block;}}