.query-form-page {
    width: 500px;
    margin: 20px auto;
    padding: 20px;
    position: relative;
}

.query-form-page h1 {
    font-family: Helvetica Neue Medium;
    color: #000;
    font-size: 32px;
    line-height: 32px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

.query-form-page .treatment-right {
    position: relative;
    width: 100%;
    height: auto;
    bottom: 0;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 10px;
    padding: 20px 30px;
    margin-bottom: 20px;
    right: 0;
}


.home-button {
    background: #ef5da2;
    font-family: Helvetica Neue Light;
    color: #fff!important;
    border: 2px solid #ef5da2;
    margin: 10px 0 0;
    font-weight: 700;
    font-size: 20px;
    border-radius: 8px;
    padding: 10px 62px 10px 14px;
    position: relative;
    width: 100%;
    text-align: left;
}