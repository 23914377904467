.popup {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.popup-inner7 {
  max-width: 400px;
  width: 100%;
  padding: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 16px;
  border-top: 0px solid #e13d32;
  background: #ffffff;
}
.popup-inner7 .iti__country-list {
  width: 320px;
  overflow-x: hidden;
}
.popup-inner7 .modal-content {
  padding: 0px;
}

.popup-inner8 {
  max-width: 400px;
  width: 100%;
  padding: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 16px;
  border-top: 0px solid #e13d32;
  background: #ffffff;
}
.popup-inner8 .iti__country-list {
  width: 320px;
  overflow-x: hidden;
}
.popup-inner8 .modal-content {
  padding: 0px;
}

.raise-ticket-form {
  padding: 20px;
}
.raise-ticket-form h2 {
  color: #000000;
  font-size: 20px;
  margin: 10px 0px 10px;
  padding: 0px 0px 20px;
  border-bottom: 1px solid #cfd4dc;
  font-family: Inter Medium;
}

.raise-ticket-form input:focus {
  border: 1px solid #ba1717;
}
.raise-ticket-form ::placeholder {
  color: #bdbdbd;
  font-size: 16px;
}
.raise-ticket-form label {
  display: block;
  font-size: 14px;
  color: #3f464f;
  margin-bottom: 5px;
  font-family: Inter Medium;
}
.raise-ticket-form input,
textarea {
  border: 1px solid #dadada;
  height: 40px;
  padding-left: 15px;
  width: 100%;
  font-size: 16px;
  border-radius: 8px;
  background: #ffffff;
  outline: none;
  color: #444;
}
.raise-ticket-form .magbox {
  border: 1px solid #dadada;
  height: 40px;
  padding-left: 15px;
  width: 100%;
  font-size: 16px;
  border-radius: 8px;
  height: 100px;
  background: #ffffff;
  outline: none;
  color: #444;
}

.raise-ticket-form .form-box {
  width: 100%;
  padding: 15px 0px 0px;
  display: flex;
  gap: 15px;
}

.raise-ticket-form .ticket-form-box {
  display: block;
  width: 100%;
}
.raise-ticket-form .iti {
  width: 100%;
}
.raise-ticket-form .submit-now {
  width: 300px;
  border: 0px;
  border-radius: 8px;
  border-radius: 8px;
  background: #da2128;
  box-shadow: 0px 0px 8.5px 0px rgba(255, 255, 255, 0.43) inset;
  color: #ffffff;
  font-size: 16px;
  padding: 10px;
  position: relative;
  width: 100%;
  margin: 0px 0 0px;
  font-family: Inter Medium;
}
.raise-ticket-form .submit-now:hover {
  background: #ffffff;
  color: #ba1717;
}

/* Close Button */
.popup-close {
  width: 30px;
  height: 30px;
  padding-top: 0px;
  display: inline-block;
  position: absolute;
  top: 30px;
  outline: none;
  border: 1px solid #e8e8e8;
  right: 40px;
  transition: ease 0.25s all;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  border-radius: 8px;
  background: #ffffff;
  font-size: 35px;
  text-align: center;
  cursor: pointer;
  line-height: 30px;
  color: #444444;
  z-index: 999999;
}
.popup-close span {
  position: absolute;
  top: -2px;
  left: 2px;
}
.popup-close:hover {
  color: #444444;
}
@media screen and (max-width: 880px) {
  .form-group1 {
    width: 90%;
    float: left;
    height: 50px;
    margin: 6px 10px;
  }
  .popup-inner {
    padding: 0px;
  }
  .popup-inner4 h2 {
    font-size: 18px;
  }
  .popup-close {
    top: 30px;
    right: 30px;
  }
  .popup-form-box {
    padding: 12px;
  }
  .popup-close span {
    position: absolute;
    top: -2px;
    left: 2px;
  }
  .popup-form .home-form-items {
    flex-direction: column;
    gap: 5px;
  }
  .popup-inner8 .popup-form label {
    font-size: 12px;
  }
  .popup-form .home-form-box {
    margin-bottom: 1px;
  }
  .popup-inner8 {
    max-width: 95%;
    width: 95%;
  }
  .popup-inner7 {
    max-width: 95%;
    width: 95%;
  }
  .form-box-head {
    margin: 0px 0px 15px;
    padding: 0px 0px 15px 55px;
  }
  .form-box-head h4 {
    font-size: 18px;
  }
  .form-box-head p {
    font-size: 10px;
  }
  .form-box-head img {
    height: 45px;
  }

  .popup-form .magbox {
    height: 80px !important;
  }
}
