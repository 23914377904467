.upload-opinion-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

h2 {
  text-align: center;
  color: #333;
}

.upload-option,
.long-text-option {
  margin-bottom: 20px;
}

.file-upload-label,
.opinion-text-label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  color: #555;
}

input[type="file"] {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.long-text-option textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: none;
}
.long-text-option input {
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: none;
}
.submit-opinion-button {
  display: block;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-opinion-button:hover {
  background-color: #45a049;
}
