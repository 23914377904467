.user-table-container {
  padding: 20px;
  overflow-x: auto;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff8f0;
}

.user-table th,
.user-table td {
  border: 1px solid #ef5da2;
  padding: 10px;
  text-align: left;
}

.user-table th {
  background-color: #ef5da2;
  color: white;
}

.user-table tr:nth-child(even) {
  background-color: #ffe3e3;
}

.user-table tr:hover {
  background-color: #fcd8d4;
}

.delete-button {
  background-color: #ef5da2;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #e65c00;
}
