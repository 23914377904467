.profile-drop {
  position: relative;
  border: none;
}
.profile-nav {
  position: relative;
  display: flex;
  margin: 0px;
  padding: 0px;
}
.profile-nav-content {
  display: none;
  position: absolute;
  right: 0px;
  min-width: 263px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 18.3px 0px rgba(0, 0, 0, 0.25);
  z-index: 6;
  margin-top: 60px;
  padding: 10px;
}
.profile-nav-content a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  border: 1px solid #ffffff;
}
.profile-nav-content a img {
  width: auto;
  height: 15px;
  margin-right: 5px;
}
.profile-nav-content a:hover {
  border-radius: 4px;
  color: #01635a;
  border: 1px solid #3ea103;
  background: rgba(150, 214, 111, 0.11);
}
.profile-nav:hover .profile-nav-content {
  display: block;
}
.profile-nav:hover .profile-drop {
  background: linear-gradient(263deg, #46bb00 5.26%, #3b9d00 104.79%);
}

.profile-box {
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 0px 0px 5px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #3ea103;
  background: rgba(150, 214, 111, 0.11);
}
.profile-box img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.profile-box h4 {
  display: block;
  font-size: 14px;
  color: #01635a;
}
.profile-box h4 span {
  display: block;
  padding-top: 6px;
  font-size: 10px;
  color: #3ea103;
}

.dropbtn {
  background-color: #ffffff;
  color: white;
  padding: 16px;
  border: block;
  position: relative;
}
.dropdown {
  display: inline-block;
}
.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 4px 18.3px 0px rgba(0, 0, 0, 0.25);
  min-width: 160px;
  height: auto;
  border: 0px solid #cccccc;
  margin-bottom: 40px;
  z-index: 999;
  left: 98%;
  top: 0px;
  border-radius: 8px;
}
.dropdown-content1 ul {
  column-count: 1;
  padding: 20px !important;
}
.dropdown-content1 li + li {
  margin-top: 0rem;
  border-top: 0px solid #6d6e71 !important;
}
.fa-chevron-right {
  float: right;
  color: #000000;
  font-size: 13px;
  margin-top: 7px;
  margin-right: -8px;
}
.dropdown-content1 a {
  font-size: 15px !important;
  display: block;
  width: 100%;
  border-bottom: 0px solid #6d6e71 !important;
  position: relative;
}
.has-dropdown li a:hover {
  color: #01635a !important;
  background: rgba(119, 191, 77, 0.24);
  border-radius: 6px;
}
.has-dropdown li:hover .dropbtn {
  color: #01635a !important;
  background: rgba(119, 191, 77, 0.24) !important;
}
.has-dropdown li:hover .fa-chevron-right {
  color: #01635a;
}
.dropdown-content1 a:hover {
  color: #fff !important;
  background: rgba(119, 191, 77, 0.24);
}
.dropdown:hover .dropdown-content1 {
  display: block;
  margin-top: 0px;
}
.has-dropdown li:hover .dropbtn img {
  filter: brightness(0) invert(1);
}

.dropdown1-content .fa-chevron-right {
  padding-right: 15px;
  color: #000000 !important;
}
.dropdown-content a:hover .fa-chevron-right {
  padding-right: 15px;
  color: #01635a !important;
}
.dropdown1-content ul li {
  width: 100%;
  padding: 0px;
  margin: 0px;
  position: relative;
}
.dropdown1-content a {
  color: #000000 !important;
  padding: 10px;
  font-size: 14px;
  display: block;
  width: 100%;
  border-radius: 6px;
  border: 0px solid #01635a !important;
}
.dropdown-content a:hover {
  color: #01635a !important;
  background: #ffffff;
}
.dropdown1-content a.active .fa-chevron-right {
  color: #01635a !important;
}

/* Clear floats after the tab */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.dropbtn {
  background: none;
  padding: 16px;
  border: none;
}

.dropdown {
  position: static !important;
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  max-width: 80rem;
  box-shadow: 1px 25px 10px rgb(0 0 0 / 20%);
  border-bottom: 1px solid #6d6e71;
  height: 500px;
  left: 0px;
  margin-left: auto;
  margin-right: auto;
  right: 0px;
  border-radius: 10px 10px 10px 10px;
  z-index: 999;
}

.dropdown1-content {
  display: block;
  width: 220px !important;
  border-radius: 6px;
  float: left;
  background: #ffffff !important;
  position: absolute;
  padding: 10px !important;
  color: #fff important;
  left: 103% !important;
  z-index: 999;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* HEADER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
  /*position: relative;*/
  padding: 0rem 1rem 0rem 1rem;
  background: var(--purple-1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-header nav:first-child {
  justify-content: left;
}
.page-header nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}

.page-header .horizontal-logo,
.page-header .search span {
  display: none;
}

.page-header .vertical-logo {
  max-width: 9.5rem;
}

.page-header .top-menu-wrapper {
  color: var(--black);
}

.page-header .top-menu-wrapper::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: background 0.5s;
}

.page-header .search {
  color: var(--white);
}

.page-header .panel,
.page-header .top-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.page-header .panel1 {
  width: 100%;
  background: var(--purple-1);
  transition-delay: 0.3s;
}

.page-header .panel2 {
  width: calc(100% - 3rem);
  background: var(--red);
  transition-delay: 0.1s;
}

.page-header .top-menu {
  display: flex;
  flex-direction: column;
  width: calc(100% - 6rem);
  overflow-y: auto;
  padding: 2rem;
  background: var(--white);
}

.page-header .top-menu-wrapper.show-offcanvas::before {
  background: var(--overlay);
  z-index: 1;
}

.page-header .top-menu-wrapper.show-offcanvas .panel,
.page-header .top-menu-wrapper.show-offcanvas .top-menu {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.7s;
}

.page-header .top-menu-wrapper.show-offcanvas .panel1 {
  transition-delay: 0s;
}

.page-header .top-menu-wrapper.show-offcanvas .panel2 {
  transition-delay: 0.2s;
}

.page-header .top-menu-wrapper.show-offcanvas .top-menu {
  transition-delay: 0.4s;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 4rem 0.5rem;
}

/* FORM
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/*.page-header .search-form {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  padding: 1rem 0;
  background: var(--purple-2);
  transition: all 0.2s;
}

.page-header .search-form.is-visible {
  visibility: visible;
  opacity: 1;
}

.page-header .search-form div {
  position: relative;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

.page-header .search-form input {
  width: 100%;
  font-size: 2rem;
  height: 4rem;
  padding: 0 2rem;
}

.page-header .search-form button {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--purple-1);
}
*/
/* TOP MENU
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header .top-menu li + li {
  margin-top: 1.5rem;
}

.page-header .top-menu > li:last-child {
  margin-top: auto;
}

.page-header ul a {
  display: inline-block;
  font-size: 1.3rem;
  color: #1a1a1a;
  text-transform: none;
  transition: color 0.35s ease-out;
  position: relative;
}

.page-header ul a:hover {
  color: #004039;
}

.page-header .has-dropdown i {
  display: none;
}

.page-header .sub-menu {
  padding: 1.5rem 2rem 0;
}

.page-header .top-menu .mob-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.page-header .top-menu .mob-block i {
  color: var(--lightgray);
}

.page-header .socials {
  display: flex;
  margin-top: 3rem;
  /* some browsers ignore the padding-bottom: 2rem of the .top-menu because it has overflow-y:auto
  https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  so we add a bottom margin to the .socials
  */
  margin-bottom: 1rem;
}

.page-header .socials li + li {
  margin-top: 0;
}

.page-header .socials .fa-stack {
  font-size: 1.7rem;
}

.page-header .socials .fab {
  font-size: 1.2rem;
}

/* FOOTER
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 1.3rem;
  color: var(--black);
}

.page-footer span {
  color: #e31b23;
}

/* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (min-width: 550px) {
  .page-header .panel1 {
    width: 60%;
  }
  .page-header .panel2 {
    width: calc(60% - 3rem);
  }
  .page-header .top-menu {
    width: calc(60% - 6rem);
  }
}

@media screen and (min-width: 768px) {
  .page-header .top-menu {
    padding: 4rem;
  }

  .page-header ul a {
    font-size: 1.6rem;
  }

  /*.page-header .search-form input {
    font-size: 2.4rem;
    height: 5rem;
    line-height: 5rem;
  }*/
}

/*@media screen and (min-width: 995px) {
  .page-header {
    padding: 0 1rem;
  }*/
nav li:last-child {
  display: block !important;
}

.page-header .panel,
.page-header .open-mobile-menu,
.page-header .vertical-logo,
.page-header .top-menu .mob-block,
.page-header .top-menu > li:last-child,
.page-header .top-menu-wrapper::before {
  display: none;
}

.page-header .horizontal-logo {
  display: block;
}

.page-header .top-menu-wrapper {
  display: flex;
  margin-top: 0px;
  align-items: center;
  color: var(--white);
}

.page-header .top-menu {
  flex-direction: row;
  position: static;
  width: auto;
  background: transparent;
  transform: none;
  padding: 0;
  overflow-y: visible;
  box-shadow: none !important;
}

.page-header .top-menu li + li {
  margin-top: 0;
}

.page-header .top-menu > li:not(:nth-last-child(2)) {
  margin-right: 0rem;
}

.page-header .top-menu > li > a {
  padding: 0.42rem 1.2rem;
}

.page-header ul a {
  font-size: 16px;
}

.page-header .has-dropdown i {
  display: inline-block;
  margin-left: 6px;
}

.page-header .sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 90%;
  transform: translateX(-50%);
  padding: 10px 10px;
  background: #01635a;
  border-radius: 10px;
}

.page-header .sub-menu a:hover {
  color: #000000;
}
.page-header .sub-menu li + li {
  margin-top: 0rem;
  border-top: 0px solid #01635a;
}

.page-header .sub-menu a {
  padding: 0.5rem 1rem !important;
  color: #5d5d5d !important;
  white-space: nowrap;
  display: block;
}

.page-header .has-dropdown {
  position: relative;
}

.page-header .has-dropdown:hover .sub-menu {
  display: block;
  box-shadow: 0px 4px 18.3px 0px rgba(0, 0, 0, 0.25);
}

.page-header .search {
  display: flex;
  align-items: center;
  margin-left: 3rem;
}

@media screen and (min-width: 1200px) {
  .page-header .search {
    margin-left: 5rem;
  }

  .page-header .search i {
    margin-right: 1.5rem;
  }

  .page-header .search span {
    display: block;
    font-weight: 500;
    font-size: 1.6rem;
  }
}
