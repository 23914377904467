.report-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  width: 250px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  padding: 0px;
  height: auto;
}

.pdf-preview {
  width: 100%;
  height: 300px;
  border: none;
  background-color: #f5f5f5;
}

.image-preview {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.view-file-link {
  text-decoration: none;
  color: #007bff;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}

.view-file-link:hover {
  background-color: #e0e0e0;
}
