.header-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
}

.greeting-icon {
  font-size: 1.5rem;
  color: #ffd700;
}

.timezone-selector {
  text-align: center;
}

#timezone-select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
  background-color: #fff;
  cursor: pointer;
}

#timezone-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.user-name {
  color: #ef5da2;
  margin-left: 5px;
}

/* User icon container for circular background */
.user-icon-container {
  background-color: #f4eadb;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* User icon */
.user-icon {
  font-size: 18px;
  color: #ef5da2;
}

/* Role container */
.user-role {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.2rem;
}

/* Time container */
.time-container {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.2rem;
  margin-left: auto;
}

.time-container .clock-icon {
  font-size: 1.2rem;
  color: #06112b;
}

.current-time {
  font-size: 1rem;
  color: #06112b;
  font-weight: bold;
}


